.index-photography-body {
  background: #f9f9f9;
}

.index-photography-header {
  position: relative;

  .slider-component {
    height: 90vh;

    ul.itemwrap {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 996;
        background: linear-gradient(rgba(0,0,0,0.2) 10%,rgba(0,0,0,0.25) 100%);
      }
    }
  }

  .intro {
    position: absolute;
    left: 0;
    right: 0;
    top: 260px;
    z-index: 998;
    text-align: center;

    @media(max-width: 767px) {
      top: 175px;
    }

    h1 {
      text-transform: uppercase;
      letter-spacing: 4px;
      color: #fff;
      font-weight: 600;
      font-size: 32px;

      @media(max-width: 767px) {
        font-size: 25px;
      }
    }

    h2 {
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      margin-top: 15px;

      @include animationDelay(.35s);
    }

    a {
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      border-radius: 35px;
      display: inline-block;
      letter-spacing: 2px;
      margin-top: 20px;
      font-size: 12px;
      padding: 13px 25px 12px;
      background: #88bee0;
      text-decoration: none;
      box-shadow: 0 4px 18px 3px rgba(0, 0, 0, 0.2);

      @include transition(all .2s);
      @include animationDelay(.35s);

      &:hover {
        background: #fff;
        color: #999;
      }
    }
  }
}

.index-photography-intro {
  margin-top: 100px;
  text-align: center;

  @media(max-width: 767px) {
    margin-top: 70px;
  }

  h2 {
    font-size: 33px;
    text-shadow: 1px 1px #fff;
    font-weight: 300;

    @media(max-width: 767px) {
      font-size: 28px;
    }
  }

  p {
    color: #777d88;
    width: 60%;
    font-size: 16px;
    margin: 30px auto 0;
    line-height: 27px;

    @media(max-width: 991px) {
      width: 70%;
    }

    @media(max-width: 767px) {
      width: 90%;
    }
  }
}

.index-photography-gallery {
  margin-top: 90px;

  .scroller {
    height: 380px;
    background-size: cover;
    background-position: center center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);

    @media(max-width: 767px) {
      height: 300px;
    }
  }
}

.index-photography-cta {
  margin-top: 70px;
  height: 410px;
  background: url('#{$imagesPath}/graphics/photo-bg.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  padding-top: 120px;

  h2 {
    color: #fff;
    font-size: 32px;
    letter-spacing: .5px;

    @media(max-width: 767px) {
      font-size: 27px;
      line-height: 35px;
    }
  }

  a {
    background: #88bee0;
    box-shadow: 0 5px 20px 3px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    color: #fff;
    display: inline-block;
    margin-top: 50px;
    padding: 20px 35px;
    font-size: 13px;
    border-radius: 3px;
    text-decoration: none;

    @include transition(all .3s);

    &:hover {
      background: #6690e4;
    }
  }
}

.index-photography-feature {
  padding-top: 120px;
  position: relative;

  @media(max-width: 767px) {
    padding-top: 80px;
  }

  p {
    text-align: center;
    color: #777d88;
    width: 70%;
    font-size: 16px;
    margin: 0 auto;
    line-height: 27px;

    @media(max-width: 767px) {
      width: 100%;
    }
  }

  .gallery {
    margin-top: 70px;

    .bg-img {
      background-size: cover;
      background-position: center center;
      width: 100%;
      margin-bottom: 10px;

      @media(max-width: 767px) {
        height: 250px !important;
      }
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5 {
      padding-left: 5px;
      padding-right: 5px;

      .bg-img {
        height: 210px;
      }
    }

    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
      padding-left: 5px;
      padding-right: 5px;

      .bg-img {
        height: 430px; 
      }
    }
    
    .col-md-12 {
      padding-left: 5px;
      padding-right: 5px;

      .bg-img {
        height: 330px; 
      }
    }
  }
}

.index-photography-footer {
  margin-top: 300px;
  background: #343746;

  @media(max-width: 767px) {
    margin-top: 200px;
  }

  .footer-wrapper {
    background: #fff;
    position: relative;
    width: 590px;
    min-height: 350px;
    margin: 0 auto;
    padding: 40px 70px;

    @media(max-width: 991px) {
      @include transform(translateY(-80px));
    }

    @media(max-width: 767px) {
      width: auto;
      padding: 30px 20px;
    }

    h3 {
      text-align: center;
      margin-top: 0;
    }

    form {
      margin-top: 30px;

      label {
        font-weight: 400;
      }

      .form-actions {
        margin-top: 30px;
        text-align: center;
      } 
    }
  }

  .social {
    text-align: center;
    padding-bottom: 100px;
    color: #fff;

    a {
      color: #fff;
      font-size: 31px;
      display: inline-block;
      margin: 0 17px;

      @include transition(all .2s ease-out);

      &:hover {
        @include transform(scale3d(1.6, 1.6, 1.6));
      }
    }
  }
}

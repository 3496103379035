.agency-alt-hero {
  min-height: 500px;
  position: relative;
  background-image: url('#{$imagesPath}/unsplash/breather-187923.jpg');
  background-size: cover;
  background-position: center center;

  @media(max-width: 767px) {
    background-position: center center;
    min-height: 270px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(85, 93, 107, 0.35);
  }

  h3 {
    position: relative;
    top: 230px;
    color: #fff;
    text-align: center;
    z-index: 1;
    font-size: 48px;
    font-weight: 500;
    line-height: 43px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.15);

    @media(max-width: 767px) {
      font-size: 33px;
      top: 130px;
    }
  }
}

.agency-alt-contact-info {
  padding: 80px 0 90px;

  .col-md-4 {
    @media(max-width: 767px) {
      text-align: center;
      margin-bottom: 25px;
    }

    @media(min-width: 992px) {
      padding-left: 25px;
      border-left: 1px solid #ddd;
      padding-top: 10px;
    }
  }

  h4 {
    font-weight: 400;

    @media(max-width: 767px) {
      font-size: 1.3rem;
    }

    i {
      font-size: 2rem;
      position: relative;
      top: 4px;
      margin-right: 8px;
    }
  }

  p {
    font-size: 17px;
  }
}

.agency-alt-map {
  position: relative;

  #map {
    height: 580px;
    width: 100%;
  }

  .location {
    background: #fff;
    padding: 45px 30px;
    max-width: 300px;
    position: absolute;
    z-index: 10;
    top: 90px;
    border-radius: 3px;
    box-shadow: 0 9px 30px 0 rgba(0,0,0,.09);

    @media(max-width: 991px) {
      max-width: 260px;
    }

    h5 {
      margin-bottom: 20px;
      color: #356c9d;
    }
  }
}
